import DomainIcon from '@mui/icons-material/Domain'
import { Box, Container, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { AlertContext } from 'src/context/AlertContext'
import { ModalCreatedCompanyContext } from 'src/context/ModalCreatedCompanyContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { Company, RequiredSignUpFields } from 'src/models/company-model'
import { goToCompany } from 'src/routes/coordinator'
import { ROUTES } from 'src/routes/Router'
import {
  createCompany,
  editCompany,
  getCompanyById,
} from 'src/service/companyService'
import { CompanyForm } from './components/Form/CompanyForm'

const mapToRequiredSignUpFields = (obj: Record<string, any>): RequiredSignUpFields[] => {
  const propertyToFieldMap: Record<string, RequiredSignUpFields> = {
    phone: 'PHONE',
    name: 'FULL_NAME',
    cpf: 'CPF',
    address: 'ADDRESS',
    dob: 'DATE_OF_BIRTH',
    mothersName: 'MOTHER_NAME',
    password: 'PASSWORD',
    requireMarketingOptIn: 'REQUIRE_COMMUNICATION',
  }

  return Object.keys(propertyToFieldMap)
    .filter(key => obj[key]) // Check if the property exists and is truthy
    .map(key => propertyToFieldMap[key]) // Map the property to the corresponding field
}

interface Props {
  isViewMode?: boolean
  viewId?: any
}

const CreateCompany = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const { t } = useTranslation()
  const { id } = useParams()
  const [company, setCompany] = useState<Company>()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)
  const { openModalCompany } = useContext(ModalCreatedCompanyContext)

  const getCompany = async () => {
    if (id || isViewMode) {
      const company = await getCompanyById(id || viewId)
      setCompany(company)
    }
  }

  const onSubmit = async (data) => {
    const requiredFields = mapToRequiredSignUpFields(data)
    const validData = {
      ...data,
      contractNumber: String(data?.contractNumber),
      companyLogo: data?.companyLogo || '',
      companyLogoSmall: data?.companyLogoSmall || '',
      aditionalFlags: data?.aditionalFlags.length > 0 ? [data.aditionalFlags] : [],
      maxDownloadBandwidthInBps: data.maxDownloadBandwidthInBps.__value,
      requiredSignUpFields: requiredFields
    }
    if (id) {
      const result = await editCompany(id, validData)
      if (result) {
        createCRUDAlert(ROUTES.COMPANY, validData.companyName, 'edited')
        goToCompany(history)
      }
      return
    }
    const result = await createCompany(validData)
    if (result) {
      createCRUDAlert(ROUTES.COMPANY, validData.companyName, 'created')
      openModalCompany(ROUTES.COMPANY, (result as Company).id)
      goToCompany(history)
    }
  }

  useEffect(() => {
    getCompany()
  }, [])

  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
      }}
    >
      <AlertComponent isView={isViewMode} />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: isViewMode ? '12px' : '24px',
        }}
      >
        <DomainIcon sx={{ width: '30.55px', height: '28px' }} />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            marginTop: '-1px',
          }}
        >
          {t('company.title')}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <CompanyForm
          data={company!}
          onSubmit={onSubmit}
          isViewMode={isViewMode}
          viewId={viewId}
        />
      </Box>
    </Container>
  )
}
export default CreateCompany
