import { yupResolver } from '@hookform/resolvers/yup'
import EditIcon from '@mui/icons-material/Edit'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, Container, SxProps, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import InputRHF from 'src/components/RHF/InputRHF'
import InputSelectRHF from 'src/components/RHF/InputSelectRHF'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import { AlertContext } from 'src/context/AlertContext'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { goToCompanyConfiguration } from 'src/routes/coordinator'
import { ROUTES } from 'src/routes/Router'
import { getPermissions } from 'src/service/authService'
import { editCompanyConfig, getCompanyConfig } from 'src/service/company-config-service'
import { companyConfigDefaultValues, companyConfigSchemaValidation } from './validators/settings-schema-validator'

const inputStyles: SxProps = {
  gridColumn: {
    xs: 'span 10',
    sm: 'span 4',
    md: 'span 4',
    lg: 'span 4',
  },
  mb: '11px',
}

const uploadOptions = [
  { id: 1, name: '1Mbps', __value: 1 },
  { id: 2, name: '5Mbps', __value: 5 },
  { id: 3, name: '10Mbps', __value: 10 },
  { id: 3, name: '15Mbps', __value: 15 },
  { id: 3, name: '20Mbps', __value: 20 },
  { id: 4, name: '25Mbps', __value: 25 },
  { id: 5, name: '50Mbps', __value: 50 },
  { id: 6, name: '100Mbps', __value: 100 },
  { id: 7, name: 'Sem limites', __value: 696969 },
]

export const Settings = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const { createCRUDAlert } = useContext(AlertContext)
  const [isViewMode, setIsViewMode] = useState<boolean>(true)
  const history = useNavigate()
  const permissions = JSON.parse(getPermissions())
  const canSeeEditButton = permissions?.config?.includes("config:update")

  const form = useForm<any>({
    mode: 'all',
    defaultValues: companyConfigDefaultValues,
    resolver: yupResolver(companyConfigSchemaValidation),
  })
  const { control, handleSubmit, reset, formState, setValue, trigger } = form

  function enableEditFields () {
    const invertedIsViewMode = !isViewMode
    setIsViewMode(invertedIsViewMode)
  }

  const getData = async () => {
    const data = await getCompanyConfig()
    if (data) {
      const selectedOption = uploadOptions.find((item) => item.__value === data.maxDownloadBandwidthInBps)
      reset(data)
      setValue('defaultUserGroup', data.defaultUserGroup)
      setValue('shouldValidateCpf', data.shouldValidateCpf)
      setValue('willUseSMSForLogin', data.willUseSMSForLogin)
      setValue('willUseSMSOnRegister', data.willUseSMSOnRegister)
      setValue('sessionTimeoutInSeconds', data.sessionTimeoutInSeconds)
      setValue('maxDownloadBandwidthInBps', selectedOption)
    }
  }

  const onSubmit = async (formData) => {
    const body = {
      defaultUserGroup: formData.defaultUserGroup,
      urlController: '',
      urlRadius: '',
      shouldValidateCpf: formData.shouldValidateCpf,
      willUseSMSForLogin: formData.willUseSMSForLogin,
      willUseSMSOnRegister: formData.willUseSMSOnRegister,
      sessionTimeoutInSeconds: formData.sessionTimeoutInSeconds,
      maxDownloadBandwidthInBps: formData.maxDownloadBandwidthInBps.__value,
    }
    const result = await editCompanyConfig(body)
    if (result) {
      createCRUDAlert(ROUTES.SETTINGS, 'A configuração', 'edited')
      goToCompanyConfiguration(history)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Container
      sx={{
        mt: '96px',
        '.MuiDataGrid-row': {
          minHeight: '62px!important',
          '.MuiDataGrid-cell': {
            paddingTop: '8px',
            paddingBottom: '8px',
            minHeight: '62px!important',
          },
        },
        width: '100%',
      }}
    >
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          mb: '24px',
        }}
      >
        <SettingsOutlinedIcon />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            marginTop: '-3px',
          }}
        >
          {t('settings.header')}
        </Typography>
      </Box>

        {canSeeEditButton && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={enableEditFields}
              variant="outlined"
              sx={{
                gap: '6px',
                width: '50%',
                height: '36px',
                mb: '40px',
                ':hover': {
                  backgroundColor: '#FF5E1E',
                  color: '#ffff',
                },
              }}
            >
              { isViewMode ? <VisibilityIcon sx={{ width: '24px', height: '24px' }} /> : <VisibilityOffIcon sx={{ width: '24px', height: '24px' }} />}
              { isViewMode ? t('config.button.edit') : t('config.button.cancel') }
            </Button>
          </Box>
        )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        <Box sx={inputStyles}>
          <SwitchRHF
            disabled={isViewMode}
            name="willUseSMSOnRegister"
            label={t('company.config.label.willUseSMSOnRegister')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('willUseSMSOnRegister')
              setValue && setValue('willUseSMSOnRegister', '')
            }}
          />
        </Box>
        <Box sx={inputStyles}>
          <SwitchRHF
            disabled={isViewMode}
            name="willUseSMSForLogin"
            label={t('company.config.label.willUseSMSForLogin')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('willUseSMSForLogin')
              setValue && setValue('willUseSMSForLogin', '')
            }}
          />
        </Box>
        <Box sx={inputStyles}>
          <SwitchRHF
            disabled={isViewMode}
            name="shouldValidateCpf"
            label={t('company.config.label.shouldValidateCpf')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('shouldValidateCpf')
              setValue && setValue('shouldValidateCpf', '')
            }}
          />
        </Box>
        <InputRHF
          control={control}
          label={t('company.config.label.defaultUserGroup')}
          placeholder={t('company.config.placeholder.defaultUserGroup')}
          name="defaultUserGroup"
          disabled={isViewMode}
          sx={inputStyles}
        />
        <InputRHF
          control={control}
          label={t('company.config.label.session.timeout')}
          type='number'
          placeholder={t('company.config.placeholder.session.timeout')}
          name="sessionTimeoutInSeconds"
          disabled={isViewMode}
          sx={inputStyles}
        />
        <InputSelectRHF
          disabled={isViewMode}
          options={uploadOptions}
          control={control}
          value='Sem limites'
          label='Limite de banda (em Mbps)'
          name="maxDownloadBandwidthInBps"
          sx={{ ...inputStyles, mt: '8px' }}
          isOptionEqualToValueFn={(option, value) => option.id === parseInt(value)}
          getOptionLabel={(option) => option?.name || ''}
        />
      </Box>
      {!isViewMode && canSeeEditButton && (
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            mt: '10px'
          }}
        >
          <Button
            type='submit'
            variant="outlined"
            disabled={!formState.isValid}
            sx={{
              gap: '6px',
              width: '50%',
              height: '36px',
              mb: '40px',
              ':hover': {
                backgroundColor: '#FF5E1E',
                color: '#ffff',
              },
            }}
          >
            <EditIcon sx={{ width: '24px', height: '24px' }} />
            {t('config.button.save')}
          </Button>
        </Box>
      )}
    </Container>
  )
}
