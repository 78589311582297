import { yupResolver } from '@hookform/resolvers/yup'
import { ContentCopy } from '@mui/icons-material'
import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined'
import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import copy from 'copy-to-clipboard'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { TitleWithDivider } from 'src/components/Dividers/TitleWithDivider'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import { UploadCompanyLogoIcon } from 'src/components/Icons/UploadCompanyLogoIcon'
import InputSelectRHF from 'src/components/RHF/InputSelectRHF'
import SwitchRHF from 'src/components/RHF/SwitchRHF'
import { Company } from 'src/models/company-model'
import { goToCompany } from 'src/routes/coordinator'
import { searchZipcode } from 'src/service/companyService'
import InputMaskRHF from '../../../../components/RHF/InputMaskRHF'
import InputRHF from '../../../../components/RHF/InputRHF'
import { companyDefaultValues, companySchemaValidation } from '../../validators/companySchemaValidator'
import { UploadCompanyPhoto } from './UploadCompanyPhoto'

const uploadOptions = [
  { id: 1, name: '1Mbps', __value: 1 },
  { id: 2, name: '5Mbps', __value: 5 },
  { id: 3, name: '10Mbps', __value: 10 },
  { id: 3, name: '15Mbps', __value: 15 },
  { id: 3, name: '20Mbps', __value: 20 },
  { id: 4, name: '25Mbps', __value: 25 },
  { id: 5, name: '50Mbps', __value: 50 },
  { id: 6, name: '100Mbps', __value: 100 },
  { id: 7, name: 'Sem limites', __value: 696969 },
]

interface CompanyFormProps {
  data: Company
  onSubmit: (data) => void
  isViewMode?: boolean
  viewId?: any
}

export const CompanyForm = ({
  data,
  onSubmit,
  isViewMode,
  viewId,
}: CompanyFormProps) => {
  const [companyLogo, setCompanyLogo] = useState<string>()
  const [companySmallLogo, setCompanySmallLogo] = useState<string>()
  const { t } = useTranslation()
  const { id } = useParams()

  const [cityName, setCityName] = useState<string>()
  const [stateName, setStateName] = useState<string>()

  const form = useForm<any>({
    mode: 'all',
    defaultValues: companyDefaultValues,
    resolver: yupResolver(companySchemaValidation),
  })
  const { control, handleSubmit, reset, formState, setValue, trigger, watch } =
    form

  const resetForm = () => {
    reset({ companyName: '' })
    setCompanyLogo('')
    setCompanySmallLogo('')
  }

  const hideInput = () => {
    if (isViewMode) {
      return viewId
    }
    if (id) {
      return id
    }
  }

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  useEffect(() => {
    if (data) {
      const selectedOption = uploadOptions.find((item) => item.__value === data.maxDownloadBandwidthInBps)
      reset(data)
      setCompanyLogo(data?.companyLogo)
      setCompanySmallLogo(data?.companyLogoSmall)
      setCityName(data?.companyCity)
      setValue('companyCity', data?.companyCity)
      setStateName(data?.companyState)
      setValue('companyState', data?.companyState)
      setValue('urlController', data?.urlController)
      setValue('urlRadius', data?.urlRadius)
      setValue('defaultUserGroup', data.defaultUserGroup)
      setValue('shouldValidateCpf', data.shouldValidateCpf)
      setValue('willUseSMSForLogin', data.willUseSMSForLogin)
      setValue('willUseSMSOnRegister', data.willUseSMSOnRegister)
      setValue('sessionTimeoutInSeconds', data.sessionTimeoutInSeconds)
      setValue('maxDownloadBandwidthInBps', selectedOption)
      setValue('aditionalFlags', data.aditionalFlags[0])
      setValue('email', true)
      setValue('phone', data?.requiredSignUpFields?.includes('PHONE'))
      setValue('cpf', data?.requiredSignUpFields?.includes('CPF'))
      setValue('name', data?.requiredSignUpFields?.includes('FULL_NAME'))
      setValue('mothersName', data?.requiredSignUpFields?.includes('MOTHER_NAME'))
      setValue('address', data?.requiredSignUpFields?.includes('ADDRESS'))
      setValue('dob', data?.requiredSignUpFields?.includes('DATE_OF_BIRTH'))
      setValue('requireMarketingOptIn', data?.requiredSignUpFields?.includes('REQUIRE_COMMUNICATION'))
      setValue('password', data?.requiredSignUpFields?.includes('PASSWORD'))
    } else {
      setValue('phone', true)
      setValue('cpf', true)
      setValue('name', true)
    }
  }, [data])

  useEffect(() => {
    setValue('email', true)
  }, [])

  useEffect(() => {
    setValue('companyLogo', companyLogo)
    setValue('companyLogoSmall', companySmallLogo)
    setValue('companyCity', cityName)
    setValue('companyState', stateName)
  }, [companyLogo, companySmallLogo])

  // Watch the phone and enable any sms
  const phoneEnabled = watch('phone')
  const passwordEnabled = watch('password')
  const smsOnRegisterEnabled = watch('willUseSMSOnRegister')
  // Watch the cpf and enable validate cpf
  const cpfEnabled = watch('cpf')

  useEffect(() => {
    if (!phoneEnabled || passwordEnabled) {
      setValue('willUseSMSForLogin', false) // Reset willUseSMSForLogin if rules are violated
    }
  }, [phoneEnabled, passwordEnabled, setValue])

  useEffect(() => {
    if (!phoneEnabled && smsOnRegisterEnabled) {
      setValue('willUseSMSOnRegister', false) // Reset willUseSMSOnRegister if Phone is disabled
    }
  }, [phoneEnabled, smsOnRegisterEnabled, setValue])

  useEffect(() => {
    // If cpf is disabled, ensure shouldValidateCpf is also disabled
    if (!cpfEnabled) {
      setValue('shouldValidateCpf', false)
    }
  }, [cpfEnabled, setValue])

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form">
      <TitleWithDivider title={t('company.form.title.basic.config')} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: isViewMode ? 0 : 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        {hideInput() && (
          <TextField
            value={`http://portal.itbuzz.com.br/${'<controladora>'}/${id || viewId
              }`}
            disabled
            sx={{
              fontSize: '14px',
              gridColumn: 'span 12',
              mb: isViewMode ? '4px' : '0px',
            }}
            InputProps={{
              endAdornment: (
                <Tooltip title={t('copy.id')} placement="left">
                  <IconButton
                    onClick={() =>
                      copy(
                        `http://portal.itbuzz.com.br/${'<controladora>'}/${id || viewId
                        }`
                      )
                    }
                    sx={{
                      borderRadius: '4px',
                      minWidth: '32px',
                      color: '#00000099',
                      ':hover': {
                        minWidth: '32px',
                        color: 'white',
                        backgroundColor: '#00000099',
                      },
                    }}
                  >
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        )}
        <InputRHF
          required
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.name')}
          name="companyName"
          placeholder={t('company.form.input.placeholder.name')}
          sx={{
            gridColumn: 'span 11',
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <SwitchRHF
          disabled={disableInput()}
          name="isActive"
          control={control}
          onChangeAction={() => {
            trigger && trigger('isActive')
            setValue && setValue('isActive', '')
          }}
          sx={{
            gridColumn: 'span 1',
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputMaskRHF
          disabled={disableInput()}
          control={control}
          label="CNPJ"
          name="cnpj"
          placeholder={t('company.form.input.placeholder.cnpj')}
          mask="99.999.999/9999-99"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mt: 1,
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.contractNumber')}
          name="contractNumber"
          placeholder={t('company.form.input.placeholder.contractNumber')}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.managerName')}
          placeholder={t('company.form.input.placeholder.managerName')}
          name="managerName"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.address')}
          placeholder={t('company.form.input.placeholder.address')}
          name="companyAddress"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 8',
              md: 'span 8',
              lg: 'span 8',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />

        <InputMaskRHF
          disabled={disableInput()}
          control={control}
          label={t('company.form.input.label.zipCode')}
          name="cep"
          placeholder={t('company.form.input.placeholder.zipCode')}
          mask="99999-999"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mt: 1,
            mb: isViewMode ? '4px' : '11px',
          }}
          onChangeAction={async (cep) => {
            const cepClear = cep.replace('_', '')
            if (cepClear.length === 9) {
              const res = await searchZipcode(cep)
              if (res.localidade !== undefined && res.uf !== undefined) {
                setCityName(res.localidade)
                setValue('companyCity', res.localidade)
                setStateName(res.uf)
                setValue('companyState', res.uf)
              } else {
                setCityName('')
                setStateName('')
                setValue('companyCity', '')
                setValue('companyState', '')
              }
            } else {
              setCityName('')
              setStateName('')
              setValue('companyCity', '')
              setValue('companyState', '')
            }
          }}
        />

        {watch('cep') && cityName && stateName && (
          <>
            <InputRHF
              disabled={true}
              control={control}
              label={t('company.form.input.label.companyState')}
              placeholder={t('company.form.input.placeholder.companyState')}
              name="companyState"
              value={stateName || ''}
              sx={{
                gridColumn: {
                  xs: 'span 10',
                  sm: 'span 4',
                  md: 'span 4',
                  lg: 'span 4',
                },
                mb: isViewMode ? '0px' : '11px',
              }}
            />

            <InputRHF
              disabled={true}
              control={control}
              label={t('company.form.input.label.companyCity')}
              placeholder={t('company.form.input.placeholder.companyCity')}
              name="companyCity"
              value={cityName || ''}
              sx={{
                gridColumn: {
                  xs: 'span 4',
                  sm: 'span 6',
                  md: 'span 8',
                  lg: 'span 8',
                },
                mb: isViewMode ? '0px' : '11px',
              }}
            />
          </>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            rowGap: 1,
            columnGap: 2,
            gridColumn: 'span 12',
          }}
        >
          <UploadCompanyPhoto
            isView={isViewMode}
            icon={<UploadCompanyLogoIcon />}
            onFileChange={(file) => setCompanyLogo(file)}
            placeholder={t('company.form.input.placeholder.logo')}
            acceptedFormats={t('input.upload.file.acceptedformats')}
            value={companyLogo}
            sx={{
              gridColumn: {
                xs: 'span 10',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              },
              mb: '11px',
            }}
          />
          <UploadCompanyPhoto
            isView={isViewMode}
            icon={
              <BrandingWatermarkOutlinedIcon
                sx={{ transform: ' rotate(180deg)' }}
              />
            }
            onFileChange={(file) => setCompanySmallLogo(file)}
            placeholder={t('company.form.input.placeholder.smallLogo')}
            acceptedFormats={t('input.upload.file.acceptedformats')}
            value={companySmallLogo}
            sx={{
              gridColumn: {
                xs: 'span 10',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              },
              mb: '11px',
            }}
          />
        </Box>
      </Box>
      <TitleWithDivider title={t('company.form.title.signup.config')} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: isViewMode ? 0 : 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
      <Box sx={{
        gridColumn: {
          xs: 'span 10',
          sm: 'span 4',
          md: 'span 4',
          lg: 'span 4',
        },
        mb: isViewMode ? '4px' : '11px',
      }}>
        <SwitchRHF
          name="email"
          label={t('company.form.input.label.signup.email')}
          control={control}
          disabled={true}
        />
      </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="phone"
            label={t('company.form.input.label.signup.phone')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('phone')
              setValue && setValue('phone', '')
            }}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="cpf"
            label={t('company.form.input.label.signup.cpf')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('cpf')
              setValue && setValue('cpf', '')
            }}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="name"
            label={t('company.form.input.label.signup.name')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('name')
              setValue && setValue('name', '')
            }}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="dob"
            label={t('company.form.input.label.signup.dob')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('dob')
              setValue && setValue('dob', '')
            }}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="address"
            label={t('company.form.input.label.signup.address')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('address')
              setValue && setValue('address', '')
            }}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="mothersName"
            label={t('company.form.input.label.signup.mothersName')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('mothersName')
              setValue && setValue('mothersName', '')
            }}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="password"
            label={t('company.form.input.label.signup.password')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('password')
              setValue && setValue('password', '')
            }}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="requireMarketingOptIn"
            label={t('company.form.input.label.signup.marketing')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('marketing')
              setValue && setValue('marketing', '')
            }}
          />
        </Box>
      </Box>
      <TitleWithDivider title={t('company.form.title.advanced.config')} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: isViewMode ? 0 : 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="willUseSMSOnRegister"
            label={t('company.form.input.label.willUseSMSOnRegister')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('willUseSMSOnRegister')
              setValue && setValue('willUseSMSOnRegister', '')
            }}
            disabled={isViewMode || !phoneEnabled}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="willUseSMSForLogin"
            label={t('company.form.input.label.willUseSMSForLogin')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('willUseSMSForLogin')
              setValue && setValue('willUseSMSForLogin', '')
            }}
            disabled={isViewMode || !phoneEnabled || passwordEnabled}
          />
        </Box>
        <Box sx={{
          gridColumn: {
            xs: 'span 10',
            sm: 'span 4',
            md: 'span 4',
            lg: 'span 4',
          },
          mb: isViewMode ? '4px' : '11px',
        }}>
          <SwitchRHF
            name="shouldValidateCpf"
            label={t('company.config.label.shouldValidateCpf')}
            control={control}
            onChangeAction={() => {
              trigger && trigger('shouldValidateCpf')
              setValue && setValue('shouldValidateCpf', '')
            }}
            disabled={isViewMode || !cpfEnabled}
          />
        </Box>
        <InputRHF
          control={control}
          label={t('company.config.label.url.controller')}
          placeholder={t('company.config.placeholder.url.controller')}
          name="urlController"
          disabled={isViewMode}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 6',
              md: 'span 6',
              lg: 'span 6',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          control={control}
          label={t('company.form.input.label.urlRadius')}
          placeholder={t('company.form.input.placeholder.urlRadius')}
          name="urlRadius"
          disabled={isViewMode}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 6',
              md: 'span 6',
              lg: 'span 6',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          control={control}
          label={t('company.form.input.label.defaultUserGroup')}
          placeholder={t('company.form.input.placeholder.defaultUserGroup')}
          name="defaultUserGroup"
          disabled={isViewMode}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          control={control}
          label={t('company.config.label.session.timeout')}
          type='number'
          placeholder={t('company.config.placeholder.session.timeout')}
          name="sessionTimeoutInSeconds"
          disabled={isViewMode}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputSelectRHF
          disabled={isViewMode}
          options={uploadOptions}
          control={control}
          value='Sem limites'
          label='Limite de banda (em Mbps)'
          name="maxDownloadBandwidthInBps"
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
            mt: '8px',
          }}
          isOptionEqualToValueFn={(option, value) => option.id === parseInt(value)}
          getOptionLabel={(option) => option?.name || ''}
        />
      </Box>
      <TitleWithDivider title={t('company.form.title.flags.config')} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: isViewMode ? 0 : 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        <InputRHF
          control={control}
          label={t('company.config.label.aditional.flags')}
          placeholder={t('company.config.placeholder.aditional.flags')}
          name="aditionalFlags"
          disabled={isViewMode}
          sx={{
            gridColumn: {
              xs: 'span 10',
              sm: 'span 4',
              md: 'span 4',
              lg: 'span 4',
            },
            mb: isViewMode ? '4px' : '11px',
          }}
        />
      </Box>

      {!isViewMode && (
        <FooterBottons
          isValid={!formState.isValid}
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToCompany}
          reset={() => resetForm()}
          size="100%"
          buttonName="company.button.create"
        />
      )}
    </Box>
  )
}
